import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_GARANTIAS);

class Patrimonio {
	obtener_patrimonios(options) {
		return http.get(utils.url_options('/patrimonios',options));
	}

	obtener_patrimonios_usuario(id) {
		return http.get(`/patrimonios/usuarios/${id}`);
	}

	crear_patrimonio(payload) {
		return http.post('/patrimonios', payload);
	}

	editar_patrimonio(id, payload) {
		return http.put(`/patrimonios/${id}`, payload);
	}
}

export default new Patrimonio();